import { useEffect, useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ErrorPage from 'next/error';
import dynamic from 'next/dynamic';

import {
	getAllURIs,
	getAllMenus,
	getTranslationStrings,
	getThemeGeneralSettings,
	getGdprConfigs,
	getNodeByURI,
	getAuthToken,
	formatData,
	getLocales,
	getRedirect,
} from '@/lib/api';
import { getWpUriFromNextPath } from '@/lib/uri';
import { getTheme, getFontFaceCSS } from '@/lib/theme';

import { getPaginationNumberFromUri } from '@/utils/pagination';

import {
	DataProvider,
	ThemeProvider,
	useGlobalContext,
} from '@superhuit/starterpack-context';
import { getDecodedTheme } from '@superhuit/starterpack-customizer';
import { withLocale } from '@superhuit/starterpack-i18n';

// import Header from '@/next-components/header';
import Layout from '@/next-components/layout';

import {
	Gdpr,
	// PageHeaderSimple,
} from '@superhuit/starterpack-blocks/renders';
import { HeaderA, MainNav, Footer } from '@/components/renders';

import { blocksThemeControls } from '@/theming-loader';
import { templatesThemeControls } from '@/theming-loader';

// // Debug performances
// import PerfsTester from '@superhuit/starterpack-perfs-tester';
// import { fetchAPITester } from '@/lib/api/fetch-api';

// remember: node can be undefined! see https://nextjs.org/docs/advanced-features/automatic-static-optimization#how-it-works
const DefaultPage = ({
	node,
	menus,
	generalSettings,
	gdprConfigs,
	preview,
	previewData,
	cssFonts,
	initialTheme,
	decodedTheme,
}) => {
	const { navIsOpen, setNavIsOpen } = useGlobalContext();
	const router = useRouter();
	const contentType = node?.__typename ?? 'error';
	let routeChangeIsBack = false;

	const Template = useMemo(
		() =>
			dynamic(() => {
				if (contentType === 'Page') {
					if (node.isCalendarPage)
						return import('@/templates/CalendarPage/index');
					else if (node.isEventsPage)
						return import('@/templates/EventsPage/index');
					else if (node.isSeasonPage)
						return import('@/templates/SeasonPage/index');
					else return import('@/templates/SinglePage/index');
				}
				if (contentType === 'Event')
					return import('@/templates/SingleEvent/index');
				if (contentType === 'Production')
					return import('@/templates/SingleProduction/index');
				if (contentType === 'Artist')
					return import('@/templates/SingleArtist/index');
				return import('@/templates/Fallback/index');
			}),
		[node]
	);

	if (!router.isFallback && !node?.uri) {
		return <ErrorPage statusCode={404} />;
	}

	// close menu when a new page gets rendered
	useEffect(() => {
		const handleRouteChange = (/*url*/) => setNavIsOpen(() => false);
		router.events.on('routeChangeComplete', handleRouteChange);

		// cleanup
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);

	// replace download links with target blanck
	useEffect(() => {
		setTimeout(() => {
			const linksDownload = document.querySelectorAll('a[download]');
			for (var i = 0; i < linksDownload.length; i++) {
				linksDownload[i].setAttribute('target', '_blank');
				linksDownload[i].removeAttribute('download');
				linksDownload[i].dataset.download = true;
			}
		}, 0);
	}, [node?.blocksJSON]);

	// Scroll to hash once page is loaded if needed
	useEffect(() => {
		const handleRouteChangeComplete = (url) => {
			let hash = window.location.hash;
			hash = hash && hash.substring(1);

			setTimeout(() => {
				if (hash) {
					const el = document.getElementById(hash);
					if (!el) return;

					el.scrollIntoView();
				} else if (!routeChangeIsBack) {
					if (window.scrollY === 0) return;

					window.scroll({
						top: 0,
						left: 0,
						behavior: 'auto',
					});
				}

				// Reset routeChangeIsBack
				routeChangeIsBack = false;

				// If we call scrollIntoView() in here without a setTimeout
				// it won't scroll properly.
			}, 0);
		};

		// executed when go-back button (action) is performed in the window.history.
		// We set a custom variable to know when the route change is actually a back button
		// to avoid to force scrolling up to the top of the page.
		router.beforePopState(() => {
			routeChangeIsBack = true;
			return true;
		});

		handleRouteChangeComplete(); // Call on first time page is loaded
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
	}, []);

	return (
		<ThemeProvider
			initialTheme={initialTheme}
			decodedTheme={decodedTheme}
			blocksThemeControls={blocksThemeControls}
			templatesThemeControls={templatesThemeControls}
			preview={preview}
		>
			<DataProvider data={generalSettings}>
				<Layout
					node={node}
					preview={preview}
					previewData={previewData}
					cssFonts={cssFonts}
				>
					<Head>
						<title>{node?.seo.title ?? node?.title}</title>
					</Head>
					<MainNav
						uri={node?.uri}
						menu={{
							primary: menus?.header,
							secondary: menus?.secondary,
							social: menus?.social_header,
						}}
						isNavOpen={navIsOpen}
						openNav={() => setNavIsOpen(() => true)}
						closeNav={() => setNavIsOpen(() => false)}
						translations={node?.translations}
						alert={node.alert}
					/>
					{router.isFallback ? (
						<HeaderA title={'Loading…'} />
					) : (
						<Template
							node={{
								...node,
								settings: generalSettings.settings,
							}}
						/>
					)}

					<Footer
						legal={menus?.legal}
						main={menus?.footer}
						social={menus?.social_footer}
						isHome={node?.isFrontPage}
					/>

					{gdprConfigs ? (
						<Gdpr preview={preview} configs={gdprConfigs} />
					) : null}
				</Layout>
			</DataProvider>
		</ThemeProvider>
	);
};

export default withLocale(DefaultPage);

export async function getStaticProps({ params, preview = false, previewData }) {
	// // Debug performances
	// const uriTester = PerfsTester();
	// uriTester.start();

	// Get locales (needs to be before uri because we need defaultLocale)
	const { locales, defaultLocale } = await getLocales();
	const defaultLocaleCode = defaultLocale?.toUpperCase();

	// Get data from current uri
	const baseUrl =
		process.env.NEXT_URL ?? process.env.VERCEL_URL ?? 'localhost:3000';
	const uri = getWpUriFromNextPath(
		params.uri ?? [],
		params.lang,
		defaultLocale
	);
	let auth = {};

	const languageCode = params.lang?.toUpperCase();

	// Get a fresh auth token
	if (preview) {
		const authToken = await getAuthToken(previewData?.refreshToken);

		// Exit preview mode if refresh token is invalid
		if (!authToken) {
			return {
				redirect: {
					destination: `/exit-preview?path=${uri}`,
					permanent: false,
				},
			};
		}

		auth = { authToken };
	}

	const redirect = await getRedirect(uri);
	if (redirect) {
		return {
			redirect,
		};
	}

	// Get page/post data
	const postDataPromise = getNodeByURI(
		uri,
		preview,
		auth,
		languageCode,
		previewData?.viewDraft ?? false
	);

	// Get theme general settings
	const generalSettingsPromise = getThemeGeneralSettings(
		params.lang?.toUpperCase() || defaultLocaleCode
	);

	const [postData, generalSettings] = await Promise.all([
		postDataPromise,
		generalSettingsPromise,
	]);

	// Return 404 if node not found (even after a redirect check) OR if it's the 404 page template
	// (not sure it happens, since we generate urls from wp already in getStaticPaths)
	if (
		!postData ||
		postData.template?.templateFilename === 'template-404.php'
	) {
		if (!postData) {
			return {
				notFound: true,
			};
		}
	}

	// Pass current uri pagination to the datas if needed
	const paginationNumber = getPaginationNumberFromUri(uri);
	if (paginationNumber) postData.currentPagination = paginationNumber;

	// Enrich page/post data
	const formattedDataPromise = postData?.uri
		? formatData(postData, generalSettings.settings)
		: null;

	// Get menus => WP menu locations as 2nd parameter
	const menusPromise = getAllMenus(languageCode, [
		'HEADER',
		'SECONDARY',
		'FOOTER',
		'LEGAL',
		'SOCIAL_HEADER',
		'SOCIAL_FOOTER',
	]);

	// Get translation strings (if no locales, it will return and empty object)
	const translationStringsPromise = getTranslationStrings(locales);

	// Get GDPR configs
	const gdprConfigsPromise = getGdprConfigs(
		params.lang?.toUpperCase() || defaultLocaleCode
	);

	const themePromise = getTheme();

	const [
		formattedData,
		menus,
		translationStrings,
		gdprConfigs,
		initialTheme,
	] = await Promise.all([
		formattedDataPromise,
		menusPromise,
		translationStringsPromise,
		gdprConfigsPromise,
		themePromise,
	]);

	const decodedTheme = getDecodedTheme(
		initialTheme,
		blocksThemeControls,
		templatesThemeControls
	);

	// // Debug performances
	// uriTester.report(`...uri ${uri}`);
	// fetchAPITester.report(`fetchAPI ${uri}`);

	return {
		// NOTE: never return `undefined` for a property, as it would fail to serialize to JSON - return `null` instead
		props: {
			node: { ...formattedData, baseUrl },
			menus: menus ? menus : null,
			translationStrings: translationStrings ? translationStrings : null, // used in withLocale
			generalSettings: generalSettings ? generalSettings : null,
			gdprConfigs: gdprConfigs ? gdprConfigs : null,
			preview,
			previewData: previewData ? previewData : null,
			cssFonts: initialTheme.tokens.fonts
				? getFontFaceCSS(initialTheme.tokens.fonts)
				: null,
			defaultLocale,
			locales,
			initialTheme,
			decodedTheme,
		},
		// TODO: maybe we can avoid all this regeneration?
		//       would be nice to only regenerate when there's recent changes in WP
		// Next.js will attempt to re-generate the page:
		// - When a request comes in
		// - At most once every x seconds
		revalidate: 7200, // In seconds
	};
}

export async function getStaticPaths() {
	const allURIs = await getAllURIs();

	return {
		paths: allURIs || [],
		fallback: 'blocking', // true or 'blocking' mandatory for preview mode to work with newly created pages
	};
}
